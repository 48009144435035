// Scripts for the homepage page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/homepage.scss';

/*
 * Component imports
 */
// Category headings
import '../components/options/category-headings';
// Category feature
import '../components/options/category-feature';
// Division logos
import '../components/general/division-logos';
// Links promotion
import '../components/promotions/links';

/*
 * Page-specific scripting
 */
